import React, { useState } from "react";
import { useLocation } from "react-router";
import { Button, TextField, Dialog, Select } from "@material-ui/core";

import CoreContainer from "../common/new/container";
import { ArrowForwardIos, Close } from "@material-ui/icons";
import CoreButton from "../common/new/button";
import { Divider, MenuItem, Typography } from "@mui/material";
import SelectClientDialog from "../selectClientDialog";
import SelectServiceDialog from "../selectServiceDialog";
import SelectProfessionalDialog from "../selectProfessional";
import { moneyMask } from "../utils";
import { useSelector } from "react-redux";
import bookingTransactionService from "../../services/bookingTransactionService";
import { getAuth } from "firebase/auth";
import { useMixpanel } from "react-mixpanel-browser";
import vacanciesService from "../../services/vacanciesService";

const NewScheduleDialog = (props) => {
	const [isNewSchedule, setIsNewSchedule] = useState(true);
	const initialDate = props.date;
	const location = useLocation();
	const { business } = useSelector((store) => store.business);
	const auth = getAuth();

	const mixpanel = useMixpanel();
	const [client, setClient] = useState("");
	const [date, setDate] = useState(initialDate);
	const [endDate, setEndDate] = useState(initialDate);
	const [time, setTime] = useState("");

	const [openSucessModal, setOpenSucessModal] = useState(false);
	const [openMessage, setOpenMessage] = useState(false);
	const [message, setMessage] = useState("Tudo certo");

	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	const [service, setService] = useState("");
	const [professional, setProfessional] = useState("");
	const [blockReason, setBlockReason] = useState("");

	const [openClientDialog, setOpenClientDialog] = useState(false);
	const [openServiceDialog, setOpenServiceDialog] = useState(false);

	const validateReservationDate = () => {
		if (business) {
			if (auth?.currentUser) {
				let startTimeDate = new Date(date),
					endTimeDate = new Date(date);

				const [hour, minute] = time.split(":").map(Number);
				startTimeDate.setHours(hour, minute);
				endTimeDate.setHours(hour, minute);

				startTimeDate.setMinutes(minute);
				endTimeDate.setMinutes(minute + service.duration);

				vacanciesService
					.checkVacancies(professional.id, startTimeDate.toISOString())
					.then((res) => {
						if (res?.data?.message) {
							setMessage(res?.data?.message);
							setOpenMessage(true);
						}
					})
					.catch((err) => {
						alert(err?.data?.message || 'Ops, ocorreu um erro inesperado')
					});
			}
		}
	};

	const saveReservation = () => {
		if (business) {
			if (auth?.currentUser) {
				let startTimeDate = new Date(date),
					endTimeDate = new Date(date);

				const [hour, minute] = time.split(":").map(Number);
				startTimeDate.setHours(hour, minute);
				endTimeDate.setHours(hour, minute);

				startTimeDate.setMinutes(minute);
				endTimeDate.setMinutes(minute + service.duration);
				let body = {
					bookings: [
						{
							id: null,
							professional_id: professional.id,
							service_id: service.id,
							start_time: startTimeDate.toISOString(),
							end_time: endTimeDate.toISOString(),
							professional,
							date: new Date(date).toISOString(),
							time,
							service,
							feature: {},
							booking: {
								id: null,
								professional_id: professional.id,
								service_id: service.id,
								start_time: startTimeDate,
								end_time: endTimeDate,
							},
							temp_id: service.temp_id,
							businessId: business.id,
							clientId: client.id,
						},
					],
					internalNote: "",
					messageClient: "",
				};

				bookingTransactionService
					.postBookingTransaction(body)
					.then((res) => {
						mixpanel.track("Nova reserva salva - WB", {
							my_custom_prop: "foo",
						});
						window.location.reload();
					})
					.catch((err) => {
						alert("O profissional não está disponível no horário escolhido.");
					});
			}
		}
	};

	const formatDateToISO = (date) => {
		return new Date(date).toISOString().slice(0, 19);
	};

	const saveScheduleBlock = () => {
		if (business) {
			if (auth?.currentUser) {
				let startTimeDate = new Date(date),
					endTimeDate = new Date(endDate);

				const [startHour, startMinute] = startTime.split(":").map(Number);
				const [endHour, endMinute] = endTime.split(":").map(Number);

				startTimeDate.setHours(startHour, startMinute);
				endTimeDate.setHours(endHour, endMinute);

				let body = {
					reason: blockReason,
					start_time: formatDateToISO(startTimeDate),
					end_time: formatDateToISO(endTimeDate),
					professional_id: professional.id,
				};

				bookingTransactionService
					.postScheduleBreak(body)
					.then((res) => {
						window.location.reload();
						console.log("Bloqueio de horário salvo com sucesso!");
					})
					.catch((err) => {
						const errorMessage = err.response?.data?.message || "Erro ao salvar o bloqueio de horário.";
						alert(errorMessage);
					});
			}
		}
	};

	return (
		<CoreContainer style={{ gap: "16px" }} transparent>
			<div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
				<Close style={{ color: "#3E3E3E" }} onClick={props.onClose}></Close>
			</div>
			<div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
				<Button
					variant="outlined"
					style={{
						backgroundColor: isNewSchedule ? "#3688EB" : "",
						color: isNewSchedule ? "#FFFFFF" : "#3E3E3E",
						borderRadius: "8px",
						width: "50%",
						textTransform: "none",
					}}
					onClick={() => setIsNewSchedule(true)}>
					Novo agendamento
				</Button>

				<Button
					variant="outlined"
					style={{
						backgroundColor: !isNewSchedule ? "#3688EB" : "",
						color: !isNewSchedule ? "#FFFFFF" : "#3E3E3E",
						borderRadius: "8px",
						width: "50%",
						textTransform: "none",
					}}
					onClick={() => setIsNewSchedule(false)}>
					Novo bloqueio
				</Button>
			</div>

			{isNewSchedule ? (
				<div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
					<div>
						<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginBottom: "8px" }}>
							Cliente
						</Typography>
						<Button
							variant="outlined"
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								borderRadius: "4px",
								width: "100%",
								textTransform: "none",
								borderColor: "rgba(0, 0, 0, 0.23)",
								padding: "14px 14px",
							}}
							endIcon={<ArrowForwardIos></ArrowForwardIos>}
							onClick={() => setOpenClientDialog(true)}>
							{client ? client.name : "Selecione um cliente"}
						</Button>
					</div>

					<div>
						<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginBottom: "8px" }}>
							Data e Hora
						</Typography>
						<div style={{ display: "flex", gap: "16px", width: "100%" }}>
							<TextField
								value={date}
								variant="outlined"
								onChange={(e) => {
									setDate(e.target.value);
								}}
								type="date"
								style={{
									width: "100%",
									borderRadius: "8px",
								}}
							/>

							<TextField
								value={time}
								variant="outlined"
								onChange={(e) => {
									setTime(e.target.value);
								}}
								type="time"
								style={{
									width: "100%",
									borderRadius: "12px",
								}}
							/>
						</div>
					</div>

					<div>
						<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginBottom: "8px" }}>
							Serviço
						</Typography>
						<Button
							variant="outlined"
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								borderRadius: "4px",
								width: "100%",
								textTransform: "none",
								borderColor: "rgba(0, 0, 0, 0.23)",
								padding: "14px 14px",
							}}
							endIcon={<ArrowForwardIos></ArrowForwardIos>}
							onClick={() => setOpenServiceDialog(true)}>
							{service ? (
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
										marginTop: "10px",
									}}>
									<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginBottom: "8px" }}>
										{service.serviceName}
									</Typography>
									<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginBottom: "8px" }}>
										{service.duration + "m"}
									</Typography>
									<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginBottom: "8px" }}>
										{moneyMask(service?.price / 100, business.currency)}
									</Typography>
								</div>
							) : (
								"Selecione um serviço"
							)}
						</Button>
					</div>

					<div>
						<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginBottom: "8px" }}>
							Profissional
						</Typography>
						<TextField
							select
							variant="outlined"
							value={professional}
							onChange={(e) => setProfessional(e.target.value)}
							fullWidth>
							{business?.professionals?.map((prof) => (
								<MenuItem key={prof.id} value={prof}>
									{prof.name}
								</MenuItem>
							))}
						</TextField>
					</div>
				</div>
			) : (
				<div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
					<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold" }}>Início</Typography>
					<div style={{ display: "flex", gap: "16px", width: "100%" }}>
						<TextField
							value={date}
							variant="outlined"
							onChange={(e) => {
								setDate(e.target.value);
							}}
							type="date"
							style={{ width: "100%" }}
						/>

						<TextField
							value={startTime}
							variant="outlined"
							onChange={(e) => {
								setStartTime(e.target.value);
							}}
							type="time"
							style={{ width: "100%" }}
						/>
					</div>

					<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold" }}>Término</Typography>
					<div style={{ display: "flex", gap: "16px", width: "100%" }}>
						<TextField
							value={endDate}
							variant="outlined"
							onChange={(e) => {
								setEndDate(e.target.value);
							}}
							type="date"
							style={{ width: "100%" }}
						/>

						<TextField
							value={endTime}
							variant="outlined"
							onChange={(e) => {
								setEndTime(e.target.value);
							}}
							type="time"
							style={{ width: "100%" }}
						/>
					</div>

					<div>
						<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold" }}>Profissional</Typography>
						<TextField
							select
							variant="outlined"
							value={professional}
							onChange={(e) => setProfessional(e.target.value)}
							fullWidth>
							{business?.professionals?.map((prof) => (
								<MenuItem key={prof.id} value={prof}>
									{prof.name}
								</MenuItem>
							))}
						</TextField>
					</div>

					<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold" }}>
						Qual o motivo do bloqueio? (opcional)
					</Typography>
					<TextField
						variant="outlined"
						value={blockReason}
						onChange={(e) => setBlockReason(e.target.value)}
						fullWidth
					/>
				</div>
			)}

			<CoreButton
				text={isNewSchedule ? "Salvar" : "Salvar"}
				color={"#3688EB"}
				onClick={() => {
					isNewSchedule ? validateReservationDate() : saveScheduleBlock();
				}}></CoreButton>

			<Dialog
				open={openClientDialog}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<SelectClientDialog onClose={() => setOpenClientDialog(false)} select={setClient} />
			</Dialog>

			<Dialog
				open={openServiceDialog}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<SelectServiceDialog onClose={() => setOpenServiceDialog(false)} select={setService} />
			</Dialog>

			<Dialog
				open={openMessage}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<Typography style={{ color: "#3E3E3E", fontSize: "18px", fontWeight: "bold", padding: "16px" }}>
					{message}
				</Typography>
				<Divider></Divider>
				<div style={{ display: "flex", justifyContent: "space-around" }}>
					<Button style={{ padding: "15px" }} onClick={() => setOpenMessage(false)}>
						Cancelar
					</Button>
					<Divider flexItem orientation="vertical"></Divider>
					<Button
						style={{ padding: "15px" }}
						onClick={() => {
							setOpenMessage(false);
							saveReservation();
						}}>
						Continuar mesmo assim? 
					</Button>
				</div>
			</Dialog>
		</CoreContainer>
	);
};

export default NewScheduleDialog;
