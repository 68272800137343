import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import { InputAdornment, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router";
import ButtonCommom from "../common/Button";
import BandeiraBRA from "assets/icons/BRA.png";
import BandeiraPOR from "assets/icons/POR.png";
import BandeiraEUA from "assets/icons/EUA.png";
import BandeiraING from "assets/icons/ING.png";
import BandeiraANG from "assets/icons/ANG.png";
import BandeiraFRA from "assets/icons/FRA.png";
import {
	telephoneMask,
	telephoneMaskPOR,
	telephoneMaskEUA,
	telephoneMaskING,
	telephoneMaskANG,
	telephoneMaskFRA,
} from "utils";
import Header from "../header";
import { Container } from "@mui/material";
import { useDispatch } from "react-redux";
import { ArrowBackIos } from "@material-ui/icons";
import CoreContainer from "../common/new/container";
import CoreAppBar from "../common/new/appBar";
import CoreButton from "../common/new/button";

const Login = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [telephoneNumber, setTelephoneNumber] = useState("");
	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [businessName, setBusinessName] = useState("");
	const [phoneDdi, setPhoneDdi] = useState(1);

	const handleChangeType = (e) => {
		setTelephoneNumber("");
		setPhoneDdi(e.target.value);
	};

	const getDdiNumber = (n) => {
		if (phoneDdi === 1) {
			return "55";
		} else if (phoneDdi === 2) {
			return "351";
		} else if (phoneDdi === 3) {
			return "1";
		} else if (phoneDdi === 4) {
			return "44";
		} else if (phoneDdi === 5) {
			return "244";
		} else if (phoneDdi === 6) {
			return "33";
		}
	};

	const handleChange = (e) => {
		if (phoneDdi === 1) {
			return telephoneMask(e);
		} else if (phoneDdi === 2) {
			return telephoneMaskPOR(e);
		} else if (phoneDdi === 3) {
			return telephoneMaskEUA(e);
		} else if (phoneDdi === 4) {
			return telephoneMaskING(e);
		} else if (phoneDdi === 5) {
			return telephoneMaskANG(e);
		} else if (phoneDdi === 6) {
			return telephoneMaskFRA(e);
		}
	};

	useEffect(() => {
		localStorage.setItem("logged", "false");
		document.getElementById("inputTelephoneNumber").focus();
		document.addEventListener("keypress", (e) => {
			if (e.key === "Enter") {
				document.getElementById("confirmButton").click();
			}
		});
	}, []);

	const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
	};

	const isNewBusiness = () => !!location?.state?.newBusiness;

	return (
		<CoreContainer style={{ gap: "16px" }}>
			<CoreAppBar progress={location?.state?.newBusiness ? 25 : null} />

			<Typography style={{ color: "#3E3E3E", fontSize: "22px", fontWeight:"bold"  }}>
				{location?.state?.newBusiness ?"Crie sua conta e comece a transformar seu negócio":"Informe seu telefone"}
			</Typography>

			{location?.state?.newBusiness && (
				<div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
					<TextField
						value={fullName}
						variant="outlined"
						label="Nome completo"
						onChange={(e) => {
							setFullName(e.target.value);
						}}
						type="text"
					/>

					<TextField
						value={email}
						variant="outlined"
						label="E-mail"
						onChange={(e) => {
							setEmail(e.target.value);
						}}
						type="email"
					/>

					<TextField
						value={businessName}
						variant="outlined"
						label="Nome do seu negócio"
						onChange={(e) => {
							setBusinessName(e.target.value);
						}}
						type="text"
					/>
				</div>
			)}

			<div style={{ display: "flex", gap: "16px", width: "100%" }}>
				<Select
					style={{
						paddingLeft: "16px",
						backgroundColor: "#D9D9D9",
						color: "#3E3E3E",
						borderRadius: "8px",
						borderBottom: "none",
					}}
					value={phoneDdi}
					label={phoneDdi}
					onChange={handleChangeType}>
					<MenuItem value={1}>
						<img style={{ width: " 20px", paddingRight: "8px" }} src={BandeiraBRA} alt="BandeiraBRA" />
						+55
					</MenuItem>
					<MenuItem value={2}>
						<img style={{ width: "  20px", paddingRight: "8px" }} src={BandeiraPOR} alt="BandeiraPOR" />
						+351
					</MenuItem>
					<MenuItem value={3}>
						<img style={{ width: " 20px", paddingRight: "8px" }} src={BandeiraEUA} alt="BandeiraEUA" />
						+1
					</MenuItem>
					<MenuItem value={4}>
						<img style={{ width: "  20px", paddingRight: "8px" }} src={BandeiraING} alt="BandeiraING" />
						+44
					</MenuItem>
					<MenuItem value={5}>
						<img style={{ width: " 20px", paddingRight: "8px" }} src={BandeiraANG} alt="BandeiraANG" />
						+244
					</MenuItem>
					<MenuItem value={6}>
						<img style={{ width: "  20px", paddingRight: "8px" }} src={BandeiraFRA} alt="BandeiraFRA" />
						+33
					</MenuItem>
				</Select>
				<TextField
					id="inputTelephoneNumber"
					value={telephoneNumber}
					variant="outlined"
					label="Ex: (11) 9 9300-0000"
					onChange={(e) => {
						handleChange(e);
						setTelephoneNumber(e.target.value);
					}}
					style={{ flex: 1 }}
					type="tel"
					inputProps={{
						pattern: "[0-9]*",
						inputMode: "numeric",
					}}
				/>
			</div>

			<Typography style={{ color: "#9C9C9C", fontSize: "16px", textAlign: "center" }}>
				Enviaremos um código via SMS para validar seu número de telefone.
			</Typography>

			<div style={{ flex: 1 }}></div>

			<CoreButton
				id="confirmButton"
			disable = {
				isNewBusiness() ? 
				telephoneNumber.length < 15 || 
				fullName === '' || 
				!validateEmail(email) || 
				businessName === '' : 
				telephoneNumber.length < 15
			}
				
				toCode={true}
				params={{
					telephoneNumber: telephoneNumber,
					ddi: getDdiNumber(phoneDdi),
					fullName,
					email,
					businessName,
				}}
			/>
		</CoreContainer>
	);
};

export default Login;
