import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		position: "relative",
		left: "50px",
		// bottom: "60px",
		width: "auto",
		padding: 0,
		margin: 0,
		borderStyle: "none none none solid",
		cursor: "pointer",
	},
	clientName: {
		fontSize: "16px",
		paddingLeft: "10px",
		color: "Black",
		// paddingTop: "10px",
		fontWeight: "bold",
	},
	reservationDetails: {
		fontSize: "16px",
		paddingLeft: "10px",
		color: "Black",
		paddingBottom: "30px",
	},
}));

const ReservationCard = (props) => {
	const classes = useStyles();
	const { clientName, serviceName, professionalName, onClick, color, startTime, endTime } = props;

	return (
		<>
			<div
				onClick={onClick}
				className={classes.cardContainer}
				style={{
					backgroundColor: color,
					border: "1px solid",
					borderRadius: "24px",
					padding: "10px",
				}}>
				{" "}
				<Typography
					style={{
						fontSize: "16px",
						paddingLeft: "10px",
						color: "white",
						paddingTop: "10px",
						fontWeight: "bold",
					}}>
					{startTime + " - " + endTime}
				</Typography>
				<Typography style={{ fontSize: "16px", paddingLeft: "10px", color: "white"}}>
					{clientName + " - " + serviceName}
				</Typography>
			</div>
		</>
	);
};
export default ReservationCard;
