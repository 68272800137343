import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import ArrowBackIosRounded from "@material-ui/icons/ArrowBackIosRounded";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { ReactComponent as Logo } from "../../../src/assets/images/Boneco31.svg";
import CoreButton from "../common/new/button";
import CoreContainer from "../common/new/container";

const Welcome = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<CoreContainer>
			<div style={{ height: "5vh"}}></div>

			{window.innerWidth < window.innerHeight && (
				<div
					style={{
						top: "-20vh",
						left: "-50vw",
						width: "200vw",
						height: "70vh",
						borderRadius: "50%",
						backgroundColor: "#f1f1f1",
						position: "absolute",
					}}
				/>
			)}

			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", zIndex:1 }}>
				<Logo />
			</div>

			<div style={{ flex: 1 }}></div>

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					width: "100%",
				}}>
				<Typography style={{ color: "#3E3E3E", fontSize: "22px", fontWeight: "bold" }}>Bem-vindo ao Prit!</Typography>

				<Typography style={{ color: "#9C9C9C", fontSize: "16px", textAlign: "center" }}>
					O app que vai revolucionar a forma que você faz e recebe seus agendamentos.
				</Typography>
			</div>

			<div
				style={{
					margin: "32px 0",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					width: "100%",
				}}>
				<CoreButton
					text={"Criar conta"}
					onClick={() =>
						navigate("/login", {
							state: {
								newBusiness: true,
							},
						})
					}
				/>
				<CoreButton
					invertColors
					text={"Já sou cadastrado!"}
					onClick={() =>
						navigate("/login", {
							state: {
								newBusiness: false,
							},
						})
					}
				/>
			</div>
		</CoreContainer>
	);
};

export default Welcome;
