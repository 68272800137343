import api from "./api";
import payout from "./payout";

export const getSubscriptionData = (config) => {
	return api.get("subscription_plan", config)
};

export const getSubscriptionCurrent = (business_id) => {
	return payout.get(`payment/subscription/prit/${business_id}`)
};

export default {
	getSubscriptionData,
	getSubscriptionCurrent,
};