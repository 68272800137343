import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button, Container, Dialog, Divider, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import ButtonCommom from "../common/Button";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from "services/firebase";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";
import { fireBaseErrors, trimTelephoneNumber } from "../../utils";
import { useMixpanel } from "react-mixpanel-browser";
import Header from "../header";
import { sendMessageWavy, sendMsgWavy, sendTokenFirebase } from "../../services/loginService";
import { ArrowBackIos } from "@material-ui/icons";
import CoreAppBar from "../common/new/appBar";
import CoreContainer from "../common/new/container";
import CoreButton from "../common/new/button";
import api from "../../services/api";
import businessAction from "../../store/actions/businessAction";
import { useDispatch } from "react-redux";

const Code = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const mixpanel = useMixpanel();
	const dispatch = useDispatch();

	const telephoneNumber = location?.state?.telephoneNumber;
	const ddi = location?.state?.ddi;

	const { fullName, email, businessName } = location?.state || {};

	const [timeCount, setTimeCount] = useState(60);

	const [error] = useState(null);

	const [codeDigits, setCodeDigits] = useState(["", "", "", "", "", ""]);

	const [appVerifier, setAppVerifier] = useState(null);

	const [user, setUser] = useState(null);

	const [openModal, setOpenModal] = useState(false);

	const [modalMessage, setModalMessage] = useState("");

	const [loading, setLoading] = useState(true);

	const sendCode = () => {
		if (telephoneNumber) {
			sendMsgWavy("+" + ddi + trimTelephoneNumber(telephoneNumber))
				.then((confirmationResult) => {
					window.confirmationResult = confirmationResult;
				})
				.catch((error) => {
					setModalMessage(error.message);
					setOpenModal(true);
				});
		}
	};

	const verifyCode = () => {
		if (window.confirmationResult && codeDigits.join("").length === 6) {
			sendMessageWavy("+" + ddi + trimTelephoneNumber(telephoneNumber), codeDigits.join(""))
				.then((result) => {
					sendTokenFirebase(auth, result.data.message).then((res) => {
						setUser(result.data.message);
						dispatch(businessAction.getBusiness());
						localStorage.setItem("logged", "true");
						mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : "", {
							my_custom_prop: "foo",
						});
						mixpanel.track("Login - WB", {
							my_custom_prop: "foo",
						});
					});
				})
				.catch((error) => {
					const errorMessages = {
						'Request failed with status code 400': 'Erro ao validar o telefone - o código fornecido não é válido',
					};
					const message = errorMessages[error.message] || fireBaseErrors(error.code);
					setModalMessage(message);
					for (let i = 0; i < 6; i++) {
						document.getElementById(`codeDigit${i}`).value = "";
					}
					setCodeDigits(["", "", "", "", "", ""]);
					document.getElementById("codeDigit0").focus();
					setOpenModal(true);
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		if (timeCount > 0) {
			setTimeout(() => {
				setTimeCount(timeCount - 1);
			}, 1000);
		}
	}, [timeCount]);

	useEffect(() => {
		setAppVerifier(
			new RecaptchaVerifier(
				"recaptcha",
				{
					size: "invisible",
					callback: (response) => { },
				},
				auth
			)
		);
		setTimeout(() => {
			setLoading(false);
			document.getElementById("codeDigit0")?.focus();
		}, 2000);
	}, []);

	useEffect(() => {
		if (appVerifier) {
			setLoading(false);
		}
	}, [appVerifier]);

	useEffect(() => {
		if (!loading) {
			sendCode();
		}
	}, [loading]);

	useEffect(() => {
		if (user) {
			localStorage.setItem("logged", "true");
			if (fullName && email && businessName) {
				api
					.get(
						"schedule/?is_message_bookings=false&is_payment_types=false&is_message_clients=true&is_sectors=false&is_pricing_type=false"
					)
					.then((res) => {
						if (res?.data?.business?.id) {
							setLoading(false);
							navigate("/menu", { state: { user: user } });
						} else {
							setLoading(false);
							navigate("/selecionar-setores", { state: { fullName, email, businessName, phone: "+" + ddi + trimTelephoneNumber(telephoneNumber) } });
						}
					})
					.catch((err) => {
						navigate("/selecionar-setores", { state: { fullName, email, businessName, phone: "+" + ddi + trimTelephoneNumber(telephoneNumber) } });
					});
			} else {
				setLoading(false);
				navigate("/menu", { state: { user: user } });
			}
		}
	}, [user]);

	const handleKeyPress = (e, index) => {
		const updatedCodeArray = [...codeDigits];
		if (e.key === "Delete" || e.key === "Backspace") {
			updatedCodeArray[index] = "";
			setCodeDigits(updatedCodeArray);
			if (index > 0) document.getElementById(`codeDigit${index - 1}`).focus();
		} else if (/^\d$/.test(e.key)) {
			updatedCodeArray[index] = e.key;
			setCodeDigits(updatedCodeArray);
			if (index < 5) document.getElementById(`codeDigit${index + 1}`).focus();
		}
	};

	const handleAutoFill = (e) => {
		const code = e.target.value;
		if (code.length === 6 && /^\d{6}$/.test(code)) {
			for (let i = 0; i < 6; i++) {
				document.getElementById(`codeDigit${i}`).value = code[i];
				document.getElementById(`codeDigit${i}`).dispatchEvent(new Event("change", { bubbles: true }));
			}
		}
	};

	return (
		<>
			<CoreContainer style={{ gap: "16px" }}>
				<CoreAppBar progress={businessName ? 50 : null} />
				<SpinnerLoading loading={loading}></SpinnerLoading>

				<Typography style={{ color: "#3E3E3E", fontSize: "22px", fontWeight: "bold" }}>
					Informe o código de confirmação
				</Typography>

				<input
					type="text"
					style={{ position: "absolute", opacity: 0, pointerEvents: "none" }}
					autoComplete="one-time-code"
					onChange={handleAutoFill}
				/>

				<div style={{ width: "100%" }}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						{[...Array(6)].map((_, index) => (
							<TextField
								key={index}
								id={`codeDigit${index}`}
								style={{ alignSelf: "center", padding: "8px" }}
								className={classes.field}
								inputProps={{
									style: {
										fontSize: "24px",
										margin: 0,
										padding: "12px 4px",
										textAlign: "center",
									},
									maxLength: 1,
									pattern: "[0-9]*",
									inputMode: "numeric",
								}}
								value={codeDigits[index]}
								onKeyDown={(e) => handleKeyPress(e, index)}
							/>
						))}
					</div>

					<Typography style={{ color: " #3E3E3E", fontSize: "16px", textAlign: "center" }}>
						Não recebeu o código?
					</Typography>

					<Typography
						style={{ color: timeCount === 0 ? " #39ADC4" : "gray", fontSize: "16px", textAlign: "center" }}
						onClick={() => {
							if (timeCount === 0) {
								sendCode();
								setTimeCount(60);
							}
						}}>
						{timeCount === 0 ? "Reenviar código" : "Reenviar em " + timeCount + "s"}
					</Typography>
				</div>

				<div style={{ flex: 1 }}></div>

				<CoreButton
					id="recaptcha"
					onClick={() => {
						setLoading(true);
						verifyCode();
						if (user) {
							setLoading(false);
							navigate("/login/data", {
								state: {
									phone: telephoneNumber,
									fullName,
									email,
									businessName,
								},
							});
						}
					}}
				/>
			</CoreContainer>
			<Dialog
				open={openModal}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<Typography style={{ margin: "30px", textAlign: "center", fontSize: "calc(0.5vw + 10px)" }}>
					<strong className={classes.littlePurple}>{modalMessage}</strong>
				</Typography>
				<Divider></Divider>
				<Button
					className={classes.littleBlue}
					style={{ padding: "15px", fontSize: "calc(0.5vw + 10px)" }}
					onClick={() => setOpenModal(false)}>
					OK
				</Button>
			</Dialog>
		</>
	);
};

export default Code;
