import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import CoreContainer from "../common/new/container";
import { ArrowBackIos, ArrowForwardIos, Close, Search, Timer } from "@material-ui/icons";
import CoreButton from "../common/new/button";
import { Divider, InputAdornment, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../address/styles";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";
import { moneyMask } from "../utils";
import professionalServiceAction from "../../store/actions/professionalServiceAction";
import professionalServiceService from "../../services/ServicesService";
import api from "../../services/api";

const SelectServiceDialog = (props) => {
	const dispatch = useDispatch();
	const classes = useStyles();

	const { business } = useSelector((store) => store.business);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [loading, setLoading] = useState(false);
	const [services, setServices] = useState([]);
	const { sector } = useSelector((store) => store.sector);
	const [editPriceDialogOpen, setEditPriceDialogOpen] = useState(false);
	const [selectedService, setSelectedService] = useState(null);
	const [newPrice, setNewPrice] = useState("");

	useEffect(() => {
		setLoading(true);
		if (sector?.length > 0) {
			setSelectedCategory(sector[0].categories[0]);
			professionalServiceService
				.getServicesByCategoryId(business.id, sector[0].categories[0].id)
				.then((res) => {
					setServices(res.data);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
				});
		}
	}, [sector]);

	const handleEditPrice = (service) => {
		setSelectedService(service);
		setNewPrice(service.price);
		setEditPriceDialogOpen(true);
	};

	const handleUpdatePrice = () => {
		const updatedService = {
			serviceId: selectedService.id,
			duration: selectedService.duration,
			price: parseFloat(newPrice) * 100,
			pricingTypeId: selectedService.pricingTypeId || "",
		};

		api
			.put(`business/${business.id}/business_service`, {
				businessServices: [updatedService],
			})
			.then(() => {
				setEditPriceDialogOpen(false);
				setServices((prevServices) =>
					prevServices.map((service) =>
						service.id === selectedService.id ? { ...service, price: updatedService.price } : service
					)
				);
			});
	};

	return (
		<CoreContainer style={{ gap: "16px" }} transparent>
			<div style={{ display: "flex" }}>
				<ArrowBackIos style={{ width: "30px", color: " #616161" }} onClick={props.onClose} />
			</div>

			<div style={{ display: "flex", gap: "16px" }}>
				{sector?.length > 0 &&
					sector[0].categories?.map((category) => (
						<Button
							onClick={() => {
								setSelectedCategory(category);
								setLoading(true);
								professionalServiceService
									.getServicesByCategoryId(business.id, category.id)
									.then((res) => {
										setServices(res.data);
										setLoading(false);
									})
									.catch((error) => {
										setLoading(false);
									});
							}}
							variant="outlined"
							style={{
								backgroundColor: selectedCategory?.id == category?.id ? "#3688EB" : "",
								color: selectedCategory?.id == category?.id ? "#FFFFFF" : "#3E3E3E",
								borderRadius: "8px",
								textTransform: "none",
								fontSize: "16px",
							}}>
							{category.name}
						</Button>
					))}
			</div>

			<Divider></Divider>

			<SpinnerLoading loading={loading}></SpinnerLoading>
			{services.map((service) => {
				return (
					<React.Fragment key={service.id}>
						<div style={{ width: "100%", height: "1px", backgroundColor: "#3E3E3E" }} />
						<div
							onClick={() => {
								props.select(service);
								props.onClose();
							}}
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
							}}>
							<Typography style={{ color: " black", fontSize: "16px" }}>{service.serviceName}</Typography>
							<div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
								<Typography style={{ color: " black", fontSize: "16px" }}>{service.duration + "m"}</Typography>

								<Typography
									style={{ color: "#3689ea", fontSize: "16px", cursor: "pointer" }}
									onClick={(e) => {
										e.stopPropagation();
										handleEditPrice(service);
									}}>
									{moneyMask(service?.price / 100, business.currency)}
								</Typography>
							</div>
						</div>
					</React.Fragment>
				);
			})}

			<Dialog
				open={editPriceDialogOpen}
				onClose={() => setEditPriceDialogOpen(false)}
				PaperProps={{ style: { borderRadius: 12 } }}>
				<div style={{ padding: "16px" }}>
					<div style={{ display: "flex" }}>
						<ArrowBackIos style={{ width: "30px", color: " #616161" }} onClick={() => setEditPriceDialogOpen(false)} />
						<Typography style={{ color: "#3E3E3E", fontSize: "18px", fontWeight: "bold", marginBottom: "8px" }}>
							Editar valor do serviço
						</Typography>
					</div>

					<DialogContent>
						<TextField
							label="Novo Preço"
							type="number"
							value={newPrice}
							onChange={(e) => setNewPrice(e.target.value)}
							fullWidth
							variant="outlined"
						/>
					</DialogContent>
					<DialogActions>
						<CoreButton onClick={() => handleUpdatePrice()} text="Salvar" color="#3689ea"></CoreButton>
					</DialogActions>
				</div>
			</Dialog>
		</CoreContainer>
	);
};

export default SelectServiceDialog;
