import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ArrowBackIosRounded from "@material-ui/icons/ArrowBackIosRounded";
import { Button, Dialog, Divider, InputAdornment, MenuItem, OutlinedInput, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import ButtonCommom from "../common/Button";
import { getAuth, updateProfile, updateEmail } from "firebase/auth";
import CommonScreen from "../common/CommonScreen";
import { ArrowBackIos, Close, Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Logo } from "../common/icons/Icons";
import axios from "axios";
import MensageDialog from "../mensageDialog";
import { KeyboardDatePicker } from "@material-ui/pickers";
import clientService from "../../services/clientService";
import { useMixpanel } from "react-mixpanel-browser";
import CoreContainer from "../common/new/container";
import CoreButton from "../common/new/button";

const NewClientDialog = (props) => {
	const auth = getAuth();
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const mixpanel = useMixpanel();

	const { open, setOpen, businessId, myProfessionalId } = props;

	const [openMessage, setOpenMessage] = useState(false);
	const [message, setMessage] = useState("Erro desconhicido, tente novamente...");

	const [newClient, setNewClient] = useState({});
	const [error, setError] = useState(false);

	const states = [
		{ label: "AC", value: "AC" },
		{ label: "AL", value: "AL" },
		{ label: "AP", value: "AP" },
		{ label: "AM", value: "AM" },
		{ label: "BA", value: "BA" },
		{ label: "CE", value: "CE" },
		{ label: "DF", value: "DF" },
		{ label: "ES", value: "ES" },
		{ label: "GO", value: "GO" },
		{ label: "MA", value: "MA" },
		{ label: "MS", value: "MS" },
		{ label: "MT", value: "MT" },
		{ label: "MG", value: "MG" },
		{ label: "PA", value: "PA" },
		{ label: "PB", value: "PB" },
		{ label: "PR", value: "PR" },
		{ label: "PE", value: "PE" },
		{ label: "PI", value: "PI" },
		{ label: "RJ", value: "RJ" },
		{ label: "RN", value: "RN" },
		{ label: "RS", value: "RS" },
		{ label: "RO", value: "RO" },
		{ label: "RR", value: "RR" },
		{ label: "SC", value: "SC" },
		{ label: "SP", value: "SP" },
		{ label: "SE", value: "SE" },
		{ label: "TO", value: "TO" },
	];

	const dateFormat = (date) => {
		let formated = date;

		if (date?.length == 2) {
			formated += "/";
		}

		if (date?.length == 5) {
			formated += "/";
		}

		return formated;
	};

	const telephoneNumberFormat = (number) => {
		let formated = "";

		for (var i = 3; i < number?.length; i++) {
			if (i === 3) {
				formated += "(";
			}
			if (i === 5) {
				formated += ")";
			}
			if (i === 10) {
				formated += "-";
			}
			formated += number.charAt(i);
		}

		return formated;
	};

	const saveClient = () => {
		if (businessId) {
			if (auth?.currentUser) {
				let body = {
					...newClient,
					businessId,
				};
				clientService
					.postClient(body, myProfessionalId)
					.then((res) => {
						setMessage("cliente salvo com sucesso!");
						setOpenMessage(true);
						setOpen(false);
						mixpanel.track("Adicionar cliente manualmente - WB", {
							my_custom_prop: "foo",
						});
					})
					.catch((err) => {
						setMessage(err.data.message);
						setOpenMessage(true);
					});
			}
		}
	};

	return (
		<CoreContainer style={{ gap: "16px" }} transparent>
			<div style={{ display: "flex" }}>
				<ArrowBackIos style={{ width: "30px", color: " #616161" }} onClick={props.onClose} />
				<Typography style={{ color: "#3E3E3E", fontSize: "18px", fontWeight: "bold", marginBottom: "8px" }}>
					Criar novo cliente
				</Typography>
			</div>
			<MensageDialog open={openMessage} setOpen={setOpenMessage} message={message}></MensageDialog>

			<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginTop: "8px" }}>Nome</Typography>
			<TextField
				value={newClient.name}
				onChange={(e) => {
					setNewClient({ ...newClient, name: e.target.value });
				}}
				style={{ width: "100%" }}
				error={error && (!newClient?.name || newClient?.name?.length == 0)}
				helperText={error && (!newClient?.name || newClient?.name?.length == 0) ? "Este campo é obrigatório" : ""}
				required
				variant="outlined"></TextField>
			<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginTop: "8px" }}>
				Telefone
			</Typography>
			<TextField
				value={telephoneNumberFormat(newClient?.phone)}
				onChange={(e) => {
					e.target.maxLength = 14;
					setNewClient({ ...newClient, phone: "+55" + e.target.value.replace(/\D/g, "") });
				}}
				InputProps={{
					startAdornment: newClient?.phone && <InputAdornment position="start">+55 </InputAdornment>,
				}}
				error={error && (!newClient?.phone || newClient?.phone?.length == 0)}
				helperText={error && (!newClient?.phone || newClient?.phone?.length == 0) ? "Este campo é obrigatório" : ""}
				required
				variant="outlined"
				style={{ width: "100%" }}></TextField>
			<Typography style={{ color: "#3E3E3E", fontSize: "14px", fontWeight: "bold", marginTop: "8px" }}>
				E-mail (opcional)
			</Typography>
			<TextField
				value={newClient.email}
				onChange={(e) => {
					setNewClient({ ...newClient, email: e.target.value });
				}}
				variant="outlined"
				style={{ width: "100%" }}></TextField>

			<div style={{ flex: 1 }}></div>

			<CoreButton
				onClick={() => {
					if (!newClient?.name || !newClient?.phone || newClient?.name?.length == 0 || newClient?.phone?.length == 0) {
						setError(true);
					} else {
						saveClient();
					}
				}}
				color={"#3688EB"}
				text="Salvar"></CoreButton>
		</CoreContainer>
	);
};

export default NewClientDialog;
