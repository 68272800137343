import api from "./api";

export const getProfessionalServices = (professionalId, categoryId) => {
	return api.get("professional_service/last_update?professional_id[]=" + professionalId + "&category_id=" + categoryId);
};

export const getServicesByCategoryId = (businessId, categoryId, language) => {
	return api.get(`business_service/business/${businessId}/new`, {
		category_id: categoryId,
		language: language || "portuguese"
	});
};

export const updateBusinessServices = (businessId, businessServices) => {
	return api.put(`business/${businessId}/business_service`, {
		businessServices: businessServices
	});
};

export default {
	getProfessionalServices,
	getServicesByCategoryId
};
