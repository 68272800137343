import React, { Fragment, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	userIcon: {
		color: "darkgray",
		width: "30px",
		height: "30px",
		margin: "10px",
		cursor: "pointer",
		borderRadius: "100%",
		alignSelf: "center",
	},
	professionalName: {
		fontSize: "15px !important",
		color: "darkgray",
		textAlign: "center",
		width: "100px",
		alignSelf: "center",
	},
}));

const ProfessionalSelect = (props) => {
	const classes = useStyles();
	const { name, onClick, selected } = props;

	return (
		<>
			<div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }} onClick={onClick}>
				<AccountCircleIcon
					className={classes.userIcon}
					style={{
						boxShadow: selected ? "0px 0px 20px 10px #c2dbf8" : "none",
					}}></AccountCircleIcon>
				<Typography className={classes.professionalName}>{name.split(" ")[0]}</Typography>
			</div>
		</>
	);
};
export default ProfessionalSelect;
