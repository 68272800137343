import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Button, Typography, Container, CircularProgress } from "@material-ui/core";
import { auth } from "firebase/app";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";
import Header from "../header";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import meAction from "../../store/actions/meAction";
import subscriptionDataAction from "../../store/actions/subscriptionDataAction";
import businessAction from "../../store/actions/businessAction";
import Modal from "@mui/material/Modal";
import { PrivacyPolicy } from "../../components/utils/index";
import CoreContainer from "../common/new/container";

const Payment = () => {
	const [selectedPlan, setSelectedPlan] = useState("Mensal");
	const [selectedPlanTitle, setSelectedPlanTitle] = useState(null);
	const [expanded, setExpanded] = useState(false);

	const subscriptionData = useSelector((state) => state.subscription_data.subscription_data);
	const subscriptionCurrent = useSelector((state) => state.subscription_current.subscription_current);

	const businessInfor = useSelector((state) => state.business.business);
	const me = useSelector((state) => state.me.me);
	const location = useLocation();

	const validationPage = location?.state?.view;
	const isCardAndPlanUpdate = location?.state?.isCardAndPlanUpdate;

	const pathParams = window.location.pathname.split("/");
	const advancedPlanId = process.env.REACT_APP_ADVANCED_PLAN_ID;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { id, token } = useParams();
	const [isLoading, setIsLoading] = useState(false);

	const [auth, setAuth] = useState(getAuth());
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if ((!pathParams || pathParams.length < 3) && !me) {
				if (user) {
					setIsLoading(true);
					let tokenAuth = user.accessToken;
					let config = {
						headers: {
							Authorization: "Bearer " + tokenAuth,
						},
					};
					dispatch(meAction.getMe(config));
					dispatch(businessAction.getBusiness(config));
				} else {
					setIsLoading(false);
					setAuth(getAuth());
					navigate("/login");
				}
			} else if (pathParams && pathParams.length > 3) {
				let tokenAuth = pathParams[3];
				setIsLoading(true);
				let config = {
					headers: {
						Authorization: "Bearer " + tokenAuth,
					},
				};
				localStorage.setItem("accessToken", tokenAuth);
				dispatch(meAction.getMe());
				dispatch(businessAction.getBusiness(config));
			}
		});

		return () => unsubscribe();
	}, [auth, token]);

	useEffect(() => {
		const request = setInterval(() => {
			if (auth?.currentUser || pathParams) {
				let businessId = pathParams.length > 3 ? pathParams[2] : businessInfor?.id;

				if (businessId) {
					setIsLoading(true);
					let config = {
						params: {
							enable: true,
							business_create_date: businessInfor?.createdAt,
							business_id: businessId,
							add_levels: false,
						},
					};
	
					dispatch(subscriptionDataAction.getSubscriptionData(config));
					dispatch(subscriptionDataAction.getSubscriptionCurrent(businessId));
	
					setIsLoading(false);
				}
				clearInterval(request);
			} else {
				setAuth(getAuth());
			}
		}, 1000);
	}, [businessInfor]);

	useEffect(() => {
		if (!validationPage && subscriptionCurrent?.status === "active" && !subscriptionCurrent.is_recurrence) {
			navigate("/menu/plan");
		} else if (!validationPage && subscriptionCurrent?.status === "active") {
			navigate("/menu/plan", { state: { card: subscriptionCurrent } });
		}
	});

	const handlePlanClick = (plan, event) => {
		setSelectedPlan(plan === selectedPlan ? null : plan);
	};

	const handleClick = (selectedPlan) => {
		if (!businessInfor?.address?.postalCode) {
			navigate("/address", { state: { id: selectedPlan?.id, isCardAndPlanUpdate: isCardAndPlanUpdate } });
		} else {
			navigate(`/menu/method/credit`, {
				state: { id: selectedPlan?.id, isCardAndPlanUpdate: isCardAndPlanUpdate },
			});
		}
		// navigate("/menu/methods", {
		//   state: {
		//     id: selectedPlanTitle?.id,
		//     isCardAndPlanUpdate: isCardAndPlanUpdate
		//   }
		// });
	};

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const sortedPlans = subscriptionData?.sort((a, b) => a.mountPrice - b.mountPrice);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		height: "80%",
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		borderRadius: "12px",
		pt: 2,
		px: 4,
		pb: 3,
		overflow: "auto",
		"&:focus": {
			outline: "none",
		},
	};

	if (!subscriptionCurrent) {
		return (
			<Container
				maxWidth="sm"
				style={{
					background: "#ffffff",
					borderRadius: 3,
					border: 0,
					color: "white",
					minWidth: "100vw",
					minHeight: "100vh",
					padding: "0 30px",
					boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<CircularProgress />
			</Container>
		);
	}

	const subtitles = {
		iniciante: "Descubra o básico com nosso plano Iniciante.",
		básico: "Mais recursos, mais possibilidades.",
		avançado: "Máxima eficiência com recursos avançados.",
		profissional: "O pacote completo para profissionais.",
	};

	return (
		<CoreContainer style={{ gap: "16px" }}>
			<Typography style={{ color: "#393762", fontSize: "28px", fontWeight: "bold", width: "50%" }}>
				Selecione seu Plano
			</Typography>

			<div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
				<Button
					variant="outlined"
					style={{
						backgroundColor: selectedPlan === "Mensal" ? "#3688EB" : "",
						color: selectedPlan === "Mensal" ? "#FFFFFF" : "#3E3E3E",
						borderRadius: "8px",
						width: "100%",
						textTransform: "none",
					}}
					onClick={(event) => handlePlanClick("Mensal", event)}>
					Mensal
				</Button>

				<Button
					variant="outlined"
					style={{
						backgroundColor: selectedPlan === "Anual" ? "#3688EB" : "",
						color: selectedPlan === "Anual" ? "#FFFFFF" : "#3E3E3E",
						borderRadius: "8px",
						width: "100%",
						textTransform: "none",
					}}
					onClick={(event) => handlePlanClick("Anual", event)}>
					Anual
				</Button>
			</div>
			<div style={{ overflowY: "scroll", overflowX: "hidden", gap: "16px" }}>
				{sortedPlans &&
					sortedPlans.map((plan) => (
						<div key={plan.id}>
							{selectedPlan === "Mensal" && plan.daysRef === 30 && (
								<div
									style={{
										position: "relative", // para que a bandeira fique relativa ao container
										padding: "16px",
										marginRight: window.innerWidth > window.innerHeight ? "8px" : "0",
										marginBottom: "16px",
										borderRadius: "8px",
										backgroundColor: "white",
										zIndex: 1,
										clipPath: "border-box",
										border: "1px solid " + (plan.id == advancedPlanId ? "#39ADC4" : "#c3c3c3"),
									}}>
									{plan.id == advancedPlanId && (
										<div
											style={{
												position: "absolute",
												top: "60px",
												right: "-25px",
												width: "120px", // ajuste o tamanho conforme necessário
												height: "15px", // ajuste o tamanho conforme necessário
												background: "linear-gradient(90deg, #39ADC4 0%, #B545E9 100%)",
												color: "white",
												fontWeight: "bold",
												textAlign: "center",
												transform: "rotate(45deg)", // para inclinar a bandeira
												transformOrigin: "top right",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												fontSize: "8px",
											}}>
											Mais escolhido
										</div>
									)}
									<Typography style={{ color: "#3E3E3E", fontSize: "22px", fontWeight: "bold", marginLeft: "16px" }}>
										{capitalizeFirstLetter(plan.title)}
									</Typography>
									<Typography style={{ color: "#3E3E3E", fontSize: "16px", marginLeft: "16px" }}>
										{subtitles[plan.title]}
									</Typography>
									<Accordion
										expanded={expanded === plan.title}
										onChange={(event, isExpanded) => {
											setSelectedPlanTitle(plan);
											event.stopPropagation();
											if (isExpanded) {
												setExpanded(plan.title);
											} else {
												setExpanded("");
											}
										}}
										style={{
											backgroundColor: "#c3c3c3",
											borderRadius: "8px",
											width: "100%",
											border: "none",
											boxShadow: "none",
										}}>
										<AccordionSummary
											expandIcon={
												<ArrowDropDownIcon
													style={{
														color: "#3688EB",
													}}
												/>
											}
											aria-controls="panel2-content"
											id="panel2-header"
											style={{ minWidth: "-webkit-fill-available", backgroundColor: "white" }}>
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
													width: "100%",
													height: "24px",
													paddingLeft: "16px",
												}}>
												<Typography
													style={{
														color: "#3E3E3E",
														fontSize: "16px",
														fontWeight: "bold",
														display: "flex",
														alignItems: "center",
													}}>
													{(plan.mountPrice / 100).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}{" "}
													<h5>/mês</h5>
												</Typography>
												<Typography style={{ color: "#3688EB", fontSize: "16px", fontWeight: "bold" }}>
													Detalhes
												</Typography>
											</div>
										</AccordionSummary>

										<AccordionDetails style={{ minWidth: "-webkit-fill-available", backgroundColor: "white" }}>
											<Typography>
												{plan.description.split("\n").map((line, index) => (
													<React.Fragment key={index}>
														<br />
														{line}
														<br />
													</React.Fragment>
												))}
											</Typography>
										</AccordionDetails>
									</Accordion>
									<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
										<Button
											variant="contained"
											color="primary"
											fullWidth
											style={{
												backgroundColor: "#3688EB",
												borderRadius: "8px",
												fontWeight: "bold",
												textTransform: "none",
												width: "70%",
											}}
											onClick={() => {
												setSelectedPlanTitle(plan);
												handleClick(plan);
											}}>
											Contratar Plano
										</Button>
									</div>
								</div>
							)}
							{selectedPlan === "Anual" && plan.daysRef === 365 && (
								<div
									style={{
										border: "1px solid #c3c3c3",
										borderRadius: "8px",
										padding: "16px",
										marginRight: window.innerWidth > window.innerHeight ? "8px" : "0",
										marginBottom: "16px",
									}}>
									<Typography style={{ color: "#3E3E3E", fontSize: "22px", fontWeight: "bold", marginLeft: "16px" }}>
										{capitalizeFirstLetter(plan.title)}
									</Typography>
									<Typography style={{ color: "#3E3E3E", fontSize: "16px", marginLeft: "16px" }}>
										{subtitles[plan.title]}
									</Typography>
									<Accordion
										expanded={expanded === plan.title}
										onChange={(event, isExpanded) => {
											setSelectedPlanTitle(plan);
											event.stopPropagation();
											if (isExpanded) {
												setExpanded(plan.title);
											} else {
												setExpanded("");
											}
										}}
										style={{
											backgroundColor: "#c3c3c3",
											borderRadius: "8px",
											width: "100%",
											border: "none",
											boxShadow: "none",
										}}>
										<AccordionSummary
											expandIcon={
												<ArrowDropDownIcon
													style={{
														color: "#3688EB",
													}}
												/>
											}
											aria-controls="panel2-content"
											id="panel2-header"
											style={{ minWidth: "-webkit-fill-available", backgroundColor: "white" }}>
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
													width: "100%",
													height: "24px",
													paddingLeft: "16px",
												}}>
												<Typography
													style={{
														color: "#3E3E3E",
														fontSize: "16px",
														fontWeight: "bold",
														display: "flex",
														alignItems: "center",
													}}>
													{(plan.mountPrice / 100).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}{" "}
													<h5>/ano</h5>
												</Typography>
												<Typography style={{ color: "#3688EB", fontSize: "16px", fontWeight: "bold" }}>
													Detalhes
												</Typography>
											</div>
										</AccordionSummary>

										<AccordionDetails style={{ minWidth: "-webkit-fill-available", backgroundColor: "white" }}>
											<Typography>
												{plan.description.split("\n").map((line, index) => (
													<React.Fragment key={index}>
														<br />
														{line}
														<br />
													</React.Fragment>
												))}
											</Typography>
										</AccordionDetails>
									</Accordion>
									<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
										<Button
											variant="contained"
											color="primary"
											fullWidth
											style={{
												backgroundColor: "#3688EB",
												borderRadius: "8px",
												fontWeight: "bold",
												textTransform: "none",
												width: "70%",
											}}
											onClick={() => {
												setSelectedPlanTitle(plan);
												handleClick(plan);
											}}>
											Contratar Plano
										</Button>
									</div>
								</div>
							)}
						</div>
					))}
			</div>
			<Typography style={{ color: "grey", fontWeight: "bold" }}>
				Ao clicar em “Contratar Plano” você concorda com nossa{" "}
				<span style={{ color: "#3688EB", cursor: "pointer" }} onClick={handleOpen}>
					Política de Privacidade
				</span>
				.
			</Typography>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description">
				<Box sx={{ ...style, width: 400 }}>
					<h2 id="parent-modal-title">Política de Privacidade</h2>
					<p id="parent-modal-description">{PrivacyPolicy}</p>
				</Box>
			</Modal>
		</CoreContainer>
	);
};

export default Payment;
