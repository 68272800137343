import api from "./api";

export const getBookings = () => {
	return api.get(
		"schedule/?is_message_bookings=false&is_payment_types=false&is_message_clients=true&is_sectors=false&is_pricing_type=false"
	);
};

export const getDatedBookings = (businessId, startTime, endTime) => {
	return api.get(
		"schedule/bookings/?business_id=" +
			businessId +
			"&start_time=" +
			startTime +
			"&end_time=" +
			endTime
	);
};



export const patchDeleteSchedule = (bookingId) => {
	return api.patch("booking/update_all", { bookingId, status: "inactive" });
};

export const patchEditSchedule = (bookingId, data) => {
	return api.patch("booking_transaction/" + bookingId, data);
};

export default {
	getBookings,
	getDatedBookings,
	patchDeleteSchedule,
	patchEditSchedule,
};
