import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import { convertMonth } from "utils";

const Calendar = (props) => {
	const backgroundColor = props?.backgroundColor;
	const selectedColor = props?.color || "#c2dbf8";
	const selectedDate = new Date(props?.date);
	const [actualDate, setActualDate] = useState(selectedDate);
	const [isExpanded, setIsExpanded] = useState(false);
	const { onChange, setDate, style, daysToNotify } = props;
	const daysOfWeek = ["D", "S", "T", "Q", "Q", "S", "S"];

	const isSmallScreen = useMediaQuery("(max-width: 600px)");

	const getDaysOfMonth = () => {
		let days = [[], [], [], [], [], []];
		let firstDay = new Date(actualDate.getFullYear(), actualDate.getMonth(), 1);
		let lastDay = new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 0).getDate();
		let previousMonthLastDay = new Date(actualDate.getFullYear(), actualDate.getMonth(), 0).getDate();
		let j = 0;

		for (let i = firstDay.getDay() - 1; i >= 0; i--) {
			days[j].push({ day: previousMonthLastDay - i, isPreviousMonth: true });
		}

		for (let i = 1; i <= lastDay; i++) {
			days[j].push({ day: i, isPreviousMonth: false });
			if (days[j].length === 7 && j < 5) {
				j++;
			}
		}

		const remainingLength = 42 - lastDay - firstDay.getDay();

		for (let i = 0; i < remainingLength; i++) {
			days[j].push(null);
			if (days[j].length === 7 && j < 5) {
				j++;
			}
		}

		return days;
	};

	const [daysOfMonth, setDaysOfMonth] = useState([]);

	const nextMonth = () => {
		if (actualDate.getMonth() === 11) {
			setActualDate(new Date(actualDate.getFullYear() + 1, 0, 1));
		} else {
			setActualDate(new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 1));
		}
	};

	const prevMonth = () => {
		if (actualDate.getMonth() === 0) {
			setActualDate(new Date(actualDate.getFullYear() - 1, 11, 1));
		} else {
			setActualDate(new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, 1));
		}
	};

	useEffect(() => {
		setDaysOfMonth(getDaysOfMonth());
	}, [actualDate]);

	const haveNotificationTo = (day) => {
		for (let i = 0; i < daysToNotify.length; i++) {
			let notifyDate = new Date(daysToNotify[i]);
			if (
				notifyDate.getDate() + 1 === day &&
				notifyDate.getMonth() === actualDate.getMonth() &&
				notifyDate.getFullYear() === actualDate.getFullYear()
			) {
				return true;
			}
		}
		return false;
	};

	const handleDateClick = (day, isPreviousMonth) => {
		if (day != null && !isPreviousMonth) {
			setDate(new Date(actualDate.getFullYear(), actualDate.getMonth(), day));
			onChange();
			if (isSmallScreen) {
				setIsExpanded(false);
			}
		}
	};

	return (
		<div style={style} className="calendar-container">
			<div style={style} className="container2">
				<div
					style={{
						backgroundColor: backgroundColor,
						width: "100%",
						padding: "5px",
						boxSizing: "border-box",
					}}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
							marginBottom: "5%",
						}}>
						<Typography
							style={{
								color: "#616161",
								fontSize: "15px",
								fontWeight: "bold",
								marginRight: "8px",
								width: "115px",
							}}>
							{`${convertMonth(actualDate.getMonth())} ${actualDate.getFullYear()}`}
						</Typography>

						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}>
							<div
								onClick={prevMonth}
								style={{
									padding: "5px",
									marginRight: "4px",
								}}>
								<ArrowBackIosNewIcon
									style={{
										color: "#616161",
										fontSize: "18px",
									}}
								/>
							</div>

							<div
								onClick={nextMonth}
								style={{
									padding: "5px",
									marginLeft: "4px",
								}}>
								<ArrowForwardIosIcon
									style={{
										color: "#616161",
										fontSize: "18px",
									}}
								/>
							</div>
						</div>
					</div>

					<div className="container-calendar-weekday">
						{daysOfWeek.map((day) => (
							<Typography key={day} className="calendar-weekday">
								{day}
							</Typography>
						))}
					</div>
				</div>

				<div
					style={{
						overflowX: "auto",
						overflowY: "hidden",
						width: "100%",
						marginTop: "5px",
					}}>
					<div
						className="calendar-grid"
						style={{
							flexDirection: isSmallScreen ? (isExpanded ? "column" : "row") : "row",
							flexWrap: isSmallScreen ? (isExpanded ? "wrap" : "nowrap") : "wrap",
							overflowX: isSmallScreen && !isExpanded ? "auto" : "hidden",
							whiteSpace: isSmallScreen && !isExpanded ? "nowrap" : "normal",
						}}>
						{isSmallScreen && !isExpanded
							? daysOfMonth[0].map((dayObj, dayIndex) =>
									dayObj ? (
										<Typography
											key={dayIndex}
											onClick={() => handleDateClick(dayObj.day, dayObj.isPreviousMonth)}
											className={`calendar-day ${
												selectedDate.getDate() === dayObj.day &&
												selectedDate.getMonth() === actualDate.getMonth() &&
												selectedDate.getFullYear() === actualDate.getFullYear()
													? "selected-day"
													: ""
											}`}
											style={{
												backgroundColor:
													selectedDate.getDate() === dayObj.day &&
													selectedDate.getMonth() === actualDate.getMonth() &&
													selectedDate.getFullYear() === actualDate.getFullYear()
														? selectedColor
														: "transparent",
											}}>
											{dayObj.day}
											{daysToNotify?.length > 0 && !dayObj.isPreviousMonth && haveNotificationTo(dayObj.day) && (
												<div className="notification-dot" />
											)}
										</Typography>
									) : (
										<div key={dayIndex} className="calendar-day empty"></div>
									)
							  )
							: daysOfMonth.map((week, weekIndex) => (
									<div key={weekIndex} className="calendar-week">
										{week.map((dayObj, dayIndex) =>
											dayObj ? (
												<Typography
													key={dayIndex}
													onClick={() => handleDateClick(dayObj.day, dayObj.isPreviousMonth)}
													className={`calendar-day ${
														selectedDate.getDate() === dayObj.day &&
														!dayObj.isPreviousMonth &&
														selectedDate.getMonth() === actualDate.getMonth() &&
														selectedDate.getFullYear() === actualDate.getFullYear()
															? "selected-day"
															: dayObj.isPreviousMonth
															? "previous-month"
															: ""
													}`}
													style={{
														backgroundColor:
															selectedDate.getDate() === dayObj.day &&
															!dayObj.isPreviousMonth &&
															selectedDate.getMonth() === actualDate.getMonth() &&
															selectedDate.getFullYear() === actualDate.getFullYear()
																? selectedColor
																: "transparent",
													}}>
													{dayObj.day}
													{daysToNotify?.length > 0 && !dayObj.isPreviousMonth && haveNotificationTo(dayObj.day) && (
														<div className="notification-dot" />
													)}
												</Typography>
											) : (
												<div key={dayIndex} className="calendar-day empty"></div>
											)
										)}
									</div>
							  ))}
					</div>

					{isSmallScreen && (
						<Button
							onClick={() => setIsExpanded(!isExpanded)}
							style={{
								display: "block",
								margin: "-5px auto",
								padding: "5px",
							}}>
							{isExpanded ? <ExpandLess /> : <ExpandMore />}
						</Button>
					)}
				</div>
			</div>

			<style>
				{`
          .calendar-container {
            flex-direction: column;
            align-items: center;
          }

          .container2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px;
            margin-left: 5%;
          }

          .calendar-grid {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 2px;
            grid-template-columns: repeat(7, 1fr);
            text-align: center;
          }

          .calendar-week {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
          }

          .calendar-day {
			width: 30px;
			height: 30px;
			color: darkgray;
			font-size: 12px;
			padding: 2px;
			text-align: center;
			border-radius: 15px;
			margin-right: 10px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.selected-day {
			color: #3689ea;
			background-color: #e0f7fa;
			width: 30px;  
			height: 30px;  
			border-radius: 50%;  
			display: flex;  
			align-items: center;  
			justify-content: center;  
			margin-top: 0;
		}

          .notification-dot {
            background-color: #3689ea;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            top: 2px;
            right: 2px;
          }

          .container-calendar-weekday {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 16px;
          }

          .calendar-weekday {
              color: #ada1a1;
              text-align: center;
              margin-right: 13%;
          }
		  .previous-month {
			  color: lightgray;
			  font-style: italic; 
		  }

          @media (max-width: 800px) {
            .calendar-grid { 
              display: grid;
              grid-template-columns: repeat(7, 1fr);
              gap: 2px;
              text-align: center;
            }

            .calendar-week {
              display: contents;
            }

             .container-calendar-weekday {
              gap: 0;
            }

            .calendar-day {
              width: 40px;
              height: 40px;
              font-size: 12px;
              margin-right: 0;
              border-radius: 20px;
            }

            .selected-day {
              color: #3689ea;
              background-color: #e0f7fa;
              font-size: 12px;
              margin-top: 0;
            }

            .calendar-container {
              padding: 10px;
            }

            .notification-dot {
              width: 8px;
              height: 8px;
              top: 4px;
              right: 4px;
            }

            .calendar-weekday {
              font-size: 14px;
              padding: 5px;
              min-width: 40px;
              text-align: center;
            }

            .container2 {
              margin-left: 0;
            }
          }
        `}
			</style>
		</div>
	);
};

export default Calendar;
